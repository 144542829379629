import React from 'react';
import './ArticleCard.css';

function ArticleCard({ article }) {
  if (!article) {
    return <div className="article-card">No article data available</div>;
  }
  
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleDateString('zh-CN', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  return (
    <div className="article-card">
      <div className="card-header">
        <div className="left-header">
          <span className="article-tag">{article.tag || '-'}</span>
          <span className="article-score">{article.score ? `${article.score}分` : '-'}</span>
        </div>
        <span className="article-date">{formatDate(article.created_at)}</span>
      </div>
      <h2 className="title">{article.title || '-'}</h2>
      <p className="summary">{article.summary || '-'}</p>
      <hr className="divider" />
      <div className="card-footer">
        {article.link ? (
          <a href={article.link} target="_blank" rel="noopener noreferrer" className="more-link">more</a>
        ) : (
          <span>-</span>
        )}
      </div>
    </div>
  );
}

export default ArticleCard;