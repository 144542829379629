import React, { useState, useEffect, useRef, useCallback } from 'react';
import { getAllArticles, handleWechatCallback } from './services/api';
import ArticleCard from './components/ArticleCard';
import './components/ArticleCard.css';
import './App.css';
import { SubscribeButton, SubscribeModal } from './components/SubscribeButton';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [articles, setArticles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const containerRef = useRef(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [direction, setDirection] = useState(0);
  const [animationDuration, setAnimationDuration] = useState(300); // 默认动画时间（毫秒）

  const [currentArticleId, setCurrentArticleId] = useState(null);
  const debounceTimerRef = useRef(null);

  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  const toggleSubscribeModal = () => {
    setShowSubscribeModal(!showSubscribeModal);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      handleWechatCallback(code)
        .then(data => {
          if (data.openid) {
            console.log('微信授权成功，openid:', data.openid);
            localStorage.setItem('wechat_openid', data.openid);
          } else {
            console.error('微信授权失败:', data.error);
          }
        })
        .catch(error => {
          console.error('处理微信回调时出错:', error);
        });
    }
  }, []);

  useEffect(() => {
    getAllArticles().then(data => {
      const articlesArray = Array.isArray(data) ? data : data.articles || [];
      setArticles(articlesArray);
      console.log('获取到的文章:', articlesArray);
      setLoading(false);

      const lastViewedId = localStorage.getItem('lastViewedArticleId');
      if (lastViewedId) {
        const index = articlesArray.findIndex(article => article.id === parseInt(lastViewedId));
        if (index !== -1) {
          setCurrentIndex(index);
          setCurrentArticleId(lastViewedId);
        }
      } else if (articlesArray.length > 0) {
        setCurrentArticleId(articlesArray[0].id.toString());
      }
    }).catch(error => {
      console.error('获取文章时出错:', error);
      setArticles([]);
      setError('加载文章失败。请稍后再试。');
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (currentArticleId) {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
      debounceTimerRef.current = setTimeout(() => {
        localStorage.setItem('lastViewedArticleId', currentArticleId);
        console.log('更新本地存储:', currentArticleId);
      }, 1666);
    }
  }, [currentArticleId]);

  useEffect(() => {
    if (articles[currentIndex]) {
      setCurrentArticleId(articles[currentIndex].id.toString());
    }
  }, [currentIndex, articles]);

  const handleTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return;
    const diff = touchStart - touchEnd;
    const threshold = 50;
    
    if (Math.abs(diff) > threshold) {
      const newDirection = diff > 0 ? 1 : -1;
      setDirection(newDirection);
      setCurrentIndex((prevIndex) => {
        const newIndex = (prevIndex + newDirection + articles.length) % articles.length;
        const distance = Math.abs(newIndex - prevIndex);
        const newDuration = Math.min(distance * 300, 1500);
        setAnimationDuration(newDuration);
        return newIndex;
      });
    } else {
      setDirection(0);
    }
    
    setTouchStart(null);
    setTouchEnd(null);
  }, [touchStart, touchEnd, articles]);

  const getVisibleCards = useCallback(() => {
    if (articles.length === 0) return [];
    const visibleCards = [];
    for (let i = -1; i <= 1; i++) {
      const index = (currentIndex + i + articles.length) % articles.length;
      visibleCards.push({ index, article: articles[index] });
    }
    return visibleCards;
  }, [articles, currentIndex]);

  const getCardStyle = useCallback((index) => {
    let offset = index - currentIndex;
    if (direction > 0 && offset === -2) offset = 1;
    if (direction < 0 && offset === 2) offset = -1;

    const baseTransform = `translateX(${offset * 100}%)`;
    return {
      transform: baseTransform,
      transition: touchEnd === null ? `transform ${animationDuration}ms ease-out` : 'none',
    };
  }, [currentIndex, touchEnd, direction, animationDuration]);

  if (loading) return <div className="App"><p>加载中...</p></div>;
  if (error) return <div className="App"><p className="error">{error}</p></div>;

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <SubscribeButton toggleModal={toggleSubscribeModal} />
      <SubscribeModal isOpen={showSubscribeModal} onClose={toggleSubscribeModal} />
      <div className="container">
        <div 
          ref={containerRef}
          className="slider"
          style={{
            position: 'relative',
            height: '100%',
            overflow: 'hidden',
            touchAction: 'pan-x',
          }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {getVisibleCards().map(({ index, article }) => (
            <div
              key={article.id || index}
              className="card-container"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                ...getCardStyle(index),
              }}
            >
              <ArticleCard article={article} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;