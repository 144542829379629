import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import './SubscribeButton.css';

function SubscribeButton({ toggleModal }) {
  return (
    <div className="subscribe-container">
      <button className="subscribe-icon" onClick={toggleModal}>
        <svg
          className="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width="28"
          height="28"
        >
        <path d="M581.2 191.048V69.168C581.2 30.944 550.128 0 511.968 0a69.176 69.176 0 0 0-69.168 69.168v121.88h138.4z" fill="#4A6FA5" p-id="29368"></path>
        <path d="M511.968 191.048h69.24V69.168C581.2 30.944 550.128 0 511.968 0" fill="#274472" p-id="29369"></path>
        <path d="M910.2 850.056s-32.872-54.68-45.696-93.224c-12.816-38.496-46.096-292.928-46.096-292.928-22.552-166.272-137.288-306.416-306.376-306.416-169.224 0-286.192 144.08-306.384 306.416 0 0-39.736 258.768-47.928 292.928-8.192 34.216-43.928 93.224-43.928 93.224h796.408z" fill="#4A90E2" p-id="29370"></path>
        <path d="M511.968 850.056h398.24s-32.872-54.68-45.696-93.224c-12.816-38.496-46.096-292.928-46.096-292.928-22.552-166.272-137.288-306.416-306.376-306.416h-0.072v692.568z" fill="#2171B5" p-id="29371"></path>
        <path d="M658.376 888.216C658.376 963.216 592.872 1024 512 1024s-146.376-60.784-146.376-135.784" fill="#FF9A8B" p-id="29372"></path>
        <path d="M733.968 850.056s-18.328-54.68-25.48-93.224c-7.144-38.496-25.696-292.928-25.696-292.928-12.552-166.272-76.512-306.416-170.76-306.416-94.344 0-159.552 144.08-170.8 306.416 0 0-22.16 258.768-26.72 292.928-4.56 34.216-24.488 93.224-24.488 93.224h443.944z" fill="#6BAED6" p-id="29373"></path>
        <path d="M511.968 157.48v692.576h222s-18.328-54.68-25.48-93.224c-7.144-38.496-25.696-292.928-25.696-292.928-12.552-166.272-76.512-306.416-170.76-306.416l-0.064-0.008z" fill="#4292C6" p-id="29374"></path>
        <path d="M511.968 888.216V1024H512c80.872 0 146.376-60.784 146.376-135.784H511.968z" fill="#FF6B6B" p-id="29375"></path>
        </svg>
      </button>
    </div>
  );
}

function SubscribeModal({ isOpen, onClose }) {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (shouldRedirect) {
      const timer = setTimeout(() => {
        // 使用 window.location.href 替代 window.open
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx33ca98b5b8af2229&redirect_uri=https%3A%2F%2Fdenoise.wokanjian.com.cn&response_type=code&scope=snsapi_base#wechat_redirect";
        setShouldRedirect(false);
      }, 800);

      return () => clearTimeout(timer);
    }
  }, [shouldRedirect]);

  if (!isOpen) return null;

  const handleSubscribe = (e) => {
    e.preventDefault();
    e.stopPropagation(); // 防止事件冒泡
    console.log("订阅按钮被点击"); // 调试日志
    toast.success("订阅成功！", { 
      autoClose: 1000,
      onOpen: () => console.log("Toast 已显示"), // 调试日志
      onClose: () => console.log("Toast 已关闭") // 调试日志
    });
    setShouldRedirect(true);
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h2>订阅降噪AI日报</h2>
        <img src="gzh_qrcode.jpg" alt="公众号二维码" className="qrcode-image" />
        <p>1. 长按图片关注公众号</p>
        <p>2. 在"设置"里开启通知</p>
        <p>3. 点击下方订阅按钮👇</p>
        <p> 我们会在 <span style={{ color: '#FF5733', fontWeight: 'bold' }}>早上 9 点</span> 推送日报给你～</p>
        <button className="close-button" onClick={onClose}>关闭</button>
        <button 
          onClick={handleSubscribe}
          className="subscribe-link"
        >
          确认订阅
        </button>
      </div>
    </div>
  );
}

export { SubscribeButton, SubscribeModal };