const API_BASE_URL = process.env.REACT_APP_API_URL

export async function getAllArticles(page = 1, perPage = 100) {
  try {
    const response = await fetch(`${API_BASE_URL}/articles?page=${page}&per_page=${perPage}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      //credentials: 'include', // 如果需要发送 cookies
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
}

export async function getArticleItem(id) {
  try {
    const response = await fetch(`${API_BASE_URL}/articles/${id}`);
    if (!response.ok) {
      throw new Error('Failed to fetch article item');
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching article item:', error);
    return null; // 返回 null，避免应用崩溃
  }
}

export async function handleWechatCallback(code) {
  try {
    const response = await fetch(`${API_BASE_URL}/auth/wechat_callback?code=${code}`);
    if (!response.ok) {
      throw new Error('Failed to handle WeChat callback');
    }
    return response.json();
  } catch (error) {
    console.error('Error handling WeChat callback:', error);
    throw error;
  }
}